/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
*/

const arData = {
  // Menu
  "menu.support": "الدعم",
  "menu.invoices": "الفواتير",
  "menu.jobcards": "بطاقات العمل",
  "menu.services": "جدول الخدمة",
  "menu.tickets": "تذاكر",
  "menu.visits": "الزيارات",

  "menu.settings": "الإعدادات",
  "menu.vehicle-master": "سيد مركبة",
  "menu.user-master": "ماجستير المستخدم",
  "menu.profile-master": "الملف الشخصي",
  "menu.company-master": "الملف الشخصي",
  "menu.live": "حي",
  "menu.passenger": "ركاب",
  "menu.passenger-view": "عرض الركاب",
  "menu.passenger-changes": "تغييرات الركاب",

  "menu.reports": "التقارير",
  "menu.idle": "تقرير الخمول",
  "menu.trip": "تقرير رحلة",
  "menu.fuel": "تقرير الوقود",
  "menu.alert": "تقرير التنبيه",
  "menu.geofence": "تقرير السياج الجغرافي",
  "menu.geofence-alerts": "تنبيهات السياج الجغرافي",
  "menu.event": "تقرير الحدث",
  "menu.vehicle": "تقرير المركبة",
  "menu.driver": "تقرير السائق",
  "menu.driver-utilization": "استخدام السائق",
  "menu.vehicle-utilization": "استخدام السيارة",
  "menu.driver-ranking": "ترتيب السائق",
  "menu.vehicle-summary": "ملخص السيارة",
  "menu.driver-trips": "رحلات السائق",
  "menu.driver-alerts": "تنبيهات السائق",
  "menu.driver-idling": "تسكع السائق",
  "menu.driver-settings": "إعدادات السائق",
  "menu.driver-view": "عرض السائق",
  "menu.log-access":"الوصول إلى السجل",


  "menu.alerts": "تنبيهات",
  "menu.story": "قصة",
  "menu.groups": "مجموعات",

  "menu.vehicles": "مركبات",
  "menu.logistics": "الخدمات اللوجستية",
  "menu.vehicle-view": "عرض السيارة",
  "menu.live-map": "خريطة حية",
  "menu.history": "التاريخ",
  "menu.trips": "رحلات",
  "menu.trip-view": "عرض الرحلة ",
  "menu.speed-chart": "مخطط السرعة",
  "menu.speed-chart-2":"مخطط السرعة 2",
  "menu.fuel-chart": "مخطط الوقود",
  "menu.efficiency": "كفاءة",
  "menu.map-search": "البحث على الخريطة",
  "menu.favorite-places": "الأماكن المفضلة",

  "menu.analytics": "تحليلات",
  "menu.drill-downs": "حفر لأسفل",
  "menu.drill-downs-geofence": "سياج جغرافي",
  "menu.drill-downs-day-summary": "ملخص اليوم",
  "menu.tracking": "تتبع",

  // --------------------------------------

  /** Common */
  "common.confirm": "تؤكد ؟?",
  "common.cancel": "إلغاء",
  "common.submit": "إرسال",
  "common.update": "تحديث",
  "common.save": "حفظ",
  "common.selected": "المحدد",
  "common.clear": "واضح",
  "common.select-all": "اختر الكل",
  "common.select-by": "حدد بواسطة",
  "common.records-showing": "تظهر السجلات",
  "common.view": "رأي",
  "common.select-vehicles": "حدد المركبات",
  "common.total": "مجموع",
  "common.running": "ادارة",
  "common.idle": "الخمول",
  "common.off": "إيقاف",
  "common.no-data": "لايوجد بيانات",
  "common.geofence": "سياج جغرافي",
  "common.select-vehicle": "اختر السيارة",
  "common.error": "خطأ",
  "common.download": "تحميل",
  "common.vehicle": "مركبة",
  "common.search": "بحث",
  "common.time": "زمن",
  "common.refresh": "تحديث",
  "common.redo-list": "إعادة المدرجة",
  "common.redo": "إعادة",
  "common.close": "قريب",

  /** Tracking Menu */
  "tracking.vehicle-list": "قائمة المركبات",
  "tracking.search-vehicles": "بحث المركبات",
  "tracking.web-id": "معرف الويب",
  "tracking.type": "نوع",
  "tracking.plate": "طبق",
  "tracking.asset": "الأصل",
  "tracking.cluster": "العنقودية",
  "tracking.model": "نموذج",
  "tracking.year": "عام",
  "tracking.selected": "المحدد",
  "tracking.filter-vehicles": "تصفية المركبات",
  "tracking.voice-enabled": "صوت ممكّن",
  "tracking.please-select-vehicle": "الرجاء تحديد السيارة",
  "tracking.more": "أكثر",
  "tracking.day": "يوم",
  "tracking.today": "اليوم",
  "tracking.go": "اذهب",
  "tracking.print-this-out": "اطبع هذا",
  "tracking.speed": "سرعة",
  "tracking.events": "الأحداث",
  "tracking.play": "لعب",
  "tracking.focus": "التركيز",
  "tracking.slower": "أبطأ",
  "tracking.faster": "بسرعة",
  "tracking.reset": "إعادة تعيين",
  "tracking.stop": "قف",
  "tracking.speed-zero-less-than-5-min-but-more-than-2-min":
    "السرعة صفر أقل من 5 دقائق ولكن أكثر من 2 دقيقة",
  "tracking.speed-zero-more-than-5-min": "السرعة صفر أكثر من 5 دقائق",
  "tracking.distance": "مسافة",
  "tracking.km": "كم",
  "tracking.show-all": "عرض الكل",
  "tracking.hide-all": "أخف كل شيء",
  "tracking.refresh": "تحديث",
  "tracking.month": "شهر",
  "tracking.trips-of-vehicle": "رحلات السيارة",
  "tracking.from": "من عند",
  "tracking.to": "إلى",
  "tracking.try-search-filter-or-select-vehicle":
    "حاول البحث عن مرشح أو حدد السيارة",
  "tracking.nothing-to-show": "لا شيء لعرضه",
  "tracking.prev": "السابق",
  "tracking.next": "التالى",
  "tracking.showing": "عرض",
  "tracking.records": "السجلات",
  "tracking.start": "بداية",
  "tracking.end": "النهاية",
  "tracking.HH:mm": "ح ح:مم",
  "tracking.driver": "سائق",
  "tracking.trips": "رحلات",
  "tracking.live": "حي",
  "tracking.history": "التاريخ",
  "tracking.engin": "انجين",
  "tracking.on": "على",
  "tracking.off": "إيقاف",
  "tracking.kmph": "كم في الساعة",
  "tracking.fuel": "وقود",
  "tracking.no-data-available": "لا تتوافر بيانات",
  "tracking.day-summary": "ملخص اليوم",
  "tracking.table": "الطاولة",
  "tracking.total-trips": "إجمالي الرحلات",
  "tracking.place": "مكان",
  "tracking.entry": "دخول",
  "tracking.geofence-alerts": "تنبيهات السياج الجغرافي",
  "tracking.speed-chart": "مخطط السرعة",
  "tracking.geo-point": "النقطة الجغرافية (خط العرض ، الغاز الطبيعي المسال)",
  "tracking.radius": "نصف القطر (بالمتر)",
  "tracking.choose-maximum-one-week":
    "اختر أسبوعًا واحدًا كحد أقصى و 10 مركبات للحصول على أفضل أداء",
  "tracking.building-no": "لا للبناء",
  "tracking.blue-plate-building-no": "رقم المبنى بلو بلايت",
  "tracking.street": "شارع",
  "tracking.blue-plate-street-no": "بلو بلايت شارع لا",
  "tracking.zone": "منطقة",
  "tracking.blue-plate-zone-no": "منطقة اللوحة الزرقاء رقم",
  "tracking.find": "تجد",
  "tracking.marker": "علامة",
  "tracking.diff": "الفارق",

  /**error */
  "error.magic-isin-the-details": "السحر في التفاصيل",
  "error.yes-it-is-needed": "نعم ، هذا مطلوب!",
  "error.title": "عفوًا ... يبدو أنه حدث خطأ!",
  "error.code": "خطا بالكود",
  "error.go-back-home": "ارجع الى البيت",

  //--------------------------------------------------------
  //Settings

  //1.Driver Settings
  "driver.reader-type": "نوع القارئ",
  "driver.rfid": "رمز RFID",
  "driver.emp": "كود Emp",
  "driver.employee-code": "رمز الموظف",
  "driver.driver-name": "اسم السائق",
  "driver.name": "اسم",
  "driver.drivers": "السائقين",
  "driver.search-drivers": "محركات البحث",
  "driver.search-vehicles": "بحث المركبات",
  "driver.assigned": "المركبات المعينة",
  "driver.action": "عمل",
  "driver.view": "رؤية",
  "driver.pending": "قيد الانتظار",
  "driver.driver-view": "عرض السائق",
  "driver.delete": "حذف السائق",
  "driver.confirm-delete": "تأكيد حذف السائق؟",
  "driver.vehicles-of": "مركبات السائق",
  "driver.please-select-vehicle": "الرجاء تحديد المركبات للتخصيص",
  "driver.please-select-driver": "الرجاء تحديد سائق",
  "driver.assign-driver": "الرجاء تحديد أسماء السائقين لتخصيصها للمركبة",
  "driver.assign-vehicle": "الرجاء تحديد المركبات",
  "driver.select-vehicle": "اختر السيارة",
  "driver.redoList": "إعادة المدرجة",
  "driver.vehicles": "مركبات",
  "driver.status": "الحالة",
  "driver.refresh": "تحديث",
  "driver.redo": "إعادة",
  "driver.active": "نشيط",
  "driver.remove": "إزالة",
  "driver.re-apply": "أعد تطبيق السائق على المركبة مرة أخرى",
  "driver.remove-driver": "ابعد السائق عن المركبة",
  "driver.confirm": "تؤكد",
  "driver.select-vehicle-assign": "حدد السيارة لتعيين هذا السائق",
  "driver.assign": "تعيين",
  "driver.vehicle": "مركبة",
  "driver.to-driver": "لسائق",
  "driver.to-vehicle": "إلى السيارة",

  // 2.Vehicle Settings
  "vehicles.info": "معلومات",
  "vehicle.info-text":
    "سيتم جدولة أي تغييرات يتم إجراؤها هنا في قناة الاتصال لتحديثها محليًا في التبادل المحتمل التالي. يشير أي نجاح إلى تغيير قناة الاتصال فقط في البداية. والتغييرات التي يمكنك رؤيتها في الإعدادات -> مدير السيارة -> إعدادات السيارة -> عناصر التحكم ، لكل مركبة على حدة",
  "vehicle.general": "الضوابط العامة",
  "vehicle.over-speed": "السرعة الزائدة",
  "vehicle.angle-threshold": "عتبة زاوية الانعطاف",
  "vehicle.speed-threshold": "حد سرعة الانعطاف",
  "vehicle.break-threshold": "عتبة الكسر الصعب",
  "vehicle.acc-threshold": "عتبة التسريع الصعبة",
  "vehicle.idle-sec": "عتبة الخمول (بالثواني)",
  "vehicle.idle-kmph": "تسكع خروج كيلومتر في الساعة",
  "vehicle.read-command": "اقرأ الأوامر",
  "vehicle.read-over": "اقرأ فوق السرعة",
  "vehicle.fuel-volume": "قراءة حجم خزان الوقود",
  "vehicle.read-angle": "قراءة زاوية المنعطفات",
  "vehicle.read-speed": "قراءة سرعة الانعطاف",
  "vehicle.read-break": "قراءة Hard Breaking",
  "vehicle.read-acc": "قراءة التسريع الصعب",
  "vehicle.read-idle": "قراءة الخمول ثانية",
  "vehicle.read-buz-status": "قراءة حالة الجرس",
  "vehicle.read-imob-status": "اقرأ حالة عدم الحركة",
  "vehicle.read-peripheral-1": "قراءة منفذ AUX1 المحيطي",
  "vehicle.read-peripheral-2": "قراءة منفذ AUX2 الطرفي",
  "vehicle.read-expiry": "قراءة انتهاء الصلاحية",
  "vehicle.read-apn": "اقرأ APN",
  "vehicle.read-odo": "اقرأ ODO",
  "vehicle.read-opt-hrs": "اقرأ ساعات العمل",
  "vehicle.get-trip": "احصل على بيانات تاريخ الرحلة",
  "vehicle.read": "اقرأ",
  "vehicle.get-event": "احصل على بيانات محفوظات الأحداث",
  "vehicle.sensitive-commands": "أوامر حساسة",
  "vehicle.mob-temp": "تعبئة مؤقتة",
  "vehicle.disable": "تعطيل",
  "vehicle.idle-buzz": "تسكع الجرس",
  "vehicle.off": "إيقاف",
  "vehicle.on": "على",
  "vehicle.alert-buzz": "تنبيه الجرس",
  "vehicle.imob": "الشلل",
  "vehicle.assign-aux": "تعيين AUX الطرفية",
  "vehicle.none": "لا شيء",
  "vehicle.driver-rfid": "سائق RFID",
  "vehicle.please-select-vehicle": "الرجاء تحديد السيارة للتحديث",
  "vehicle.you-are-update": "أنت على وشك التحديث",
  "vehicle.tooltip-best-value-7": "أفضل قيمة بين 5 و 15 ، الافتراضي هو 7",
  "vehicle.tooltip-best-value-35":
    "أفضل قيمة تتراوح بين 25 و 50 ، القيمة الافتراضية هي 35",
  "vehicle.tooltip-best-value-10":
    "أفضل قيمة بين 7 و 15 ، القيمة الافتراضية هي 10",
  "vehicle.tooltip-best-value-9": "أفضل قيمة بين 7 و 15 ، الافتراضي هو 9",
  "vehicle.tooltip-best-value-600":
    "أفضل قيمة بين 600 و 2400 ، الافتراضي 600 ثانية",
  "vehicle.tooltip-best-value-120":
    "أفضل قيمة تتراوح بين 20 و 360 ، القيمة الافتراضية هي 120",
  "vehicle.tooltip-best-value-10kmph":
    "أفضل قيمة تتراوح بين 0 و 10 ، القيمة الافتراضية هي 10 كم في الساعة",
  "vehicle.vehicle-setting-tab": "إعدادات السيارة",
  "vehicle.common-setting-tab": "الإعدادات العامة",
  "vehicle.read-controls": "قراءة الضوابط",
  "vehicle.controls": "ضوابط السيارة",
  "vehicle.type": "نوع",
  "vehicle.status": "الحالة",
  "vehicle.value": "القيمة",
  "vehicle.action": "عمل",
  "vehicle.no": "لا",
  "vehicle.jt": "JT",
  "vehicle.rcs": "RCS",
  "vehicle.current": "القيمة الحالية",
  "vehicle.default": "الإعداد الافتراضي هو ON",
  "vehicle.selected-vehicle": "اختر السيارة",
  "vehicle.drivers-of": "سائقي المركبات",
  "vehicle.details": "تفاصيل",
  "vehicle.tab-controls": "ضوابط",
  "vehicle.drivers": "السائقين",
  "vehicle.select-continue": "الرجاء تحديد سيارة للمتابعة",
  "vehicle.redo-list": "إعادة المدرجة",
  "vehicle.redo": "إعادة",
  "vehicle.name": "اسم",
  "vehicle.emp": "كود Emp",
  "vehicle.re-apply": "أعد تطبيق السائقين على المركبة مرة أخرى",
  "vehicle.confirm": "تؤكد",
  "vehicle.drivers-?": "السائقين ؟",
  "vehicle.remove": "أبعد السائق عن المركبة",
  "vehicle.read-controls-device": "قراءة قيمة الضوابط من الجهاز",
  "vehicle.select-driver": "حدد السائق",
  "vehicle.edit": "تحرير تفاصيل السيارة",
  "vehicle.not-select": "المركبة غير محددة",
  "vehicle.title": "عنوان المركبة",
  "vehicle.plate-no": "رقم لوحة",
  "vehicle.asset-no": "رقم الأصل",
  "vehicle.vehicle-type": "نوع السيارة",
  "vehicle.manfr-name": "اسم المصنع",
  "vehicle.modal": "نموذج",
  "vehicle.tyre": "إطار العجلة",
  "vehicle.fuel-type": "نوع الوقود",
  "vehicle.engine": "محرك",
  "vehicle.year": "عام",
  "vehicle.sim-no": "رقم SIM",
  "vehicle.sim-provider": "مزود SIM",
  "vehicle.cluster": "العنقودية",
  "vehicle.device-id": "معرف الجهاز",

  // 3.Profile

  "profile.company-logo": "شعار الشركة",
  "profile.change": "يتغيرون",
  "profile.details-edit": "تفاصيل تحرير",
  "profile.cmpny-name": "اسم الشركة",
  "profile.attribute-edit": "سمات تحرير",
  "profile.section": "الجزء",
  "profile.attribute": "السمات",
  "profile.value": "القيمة",
  "profile.live-map": "خريطة حية",
  "profile.no-data": "لا يوجد وقت بيانات",

  // 4.User

  "user.executive-from": "استمارة تنفيذية",
  "user.user-role": "دور المستخدم",
  "user.user-email": "البريد الإلكتروني",
  "user.new-password": "كلمة مرور جديدة",
  "user.first-name": "الاسم الاول",
  "user.last-name": "الكنية",
  "user.nick-name": "كنية",
  "user.contact-no": "رقم الاتصال",
  "user.exe-list": "القائمة التنفيذية",
  "user.user-access": "وصول المستخدم مقيد!",
  "user.user-status": "الحالة",
  "user.user-name": "اسم المستخدم",
  "user.group": "مجموعة",

  // 5.Passengers
  "passenger.tab-list": "قائمة الركاب",
  "passenger.tab-cluster": "مجموعة الركاب",
  "passenger.passenger-name": "اسم الراكب",
  "passenger.passenger-code": "كود الراكب",
  "passenger.passenger-rfid": "راكب RFID",
  "passenger.passengers": "ركاب",
  "passenger.search-passenger": "البحث عن الركاب",
  "passenger.cluster": "العنقودية",
  "passenger.name": "اسم",
  "passenger.emp": "كود Emp",
  "passenger.rfid": "RFID",
  "passenger.Action": "عمل",
  "passenger.view": "رأي",
  "passenger.passenger-view": "عرض الركاب",
  "passenger.clusters": "مجموعات الركاب",
  "passenger.vehicles": "مركبات",
  "passenger.status": "الحالة",
  "passenger.re-apply": "أعد وضع الركاب على المركبة مرة أخرى",
  "passenger.delete": "حذف المسافر",
  "passenger.confirm": "تأكيد الحذف؟",
  "passenger.vehicles-of": "مركبات الركاب",
  "passenger.vehicles-to": "المركبات المطلوب التنازل عنها",
  "passenger.search-vehicles": "بحث المركبات",
  "passenger.re-apply-drivers": "أعد تطبيق السائقين على المركبة مرة أخرى",
  "passenger.confirm-passenger": "تؤكد",
  "passenger.passengers-?": "ركاب؟",
  "passenger.re-apply-passengers": "أعد تطبيق الراكب على المركبة مرة أخرى",
  "passenger.remove": "إبعاد الراكب عن المركبة",
  "passenger.selct-vehicle": "اختر السيارة",
  "passenger.assign": "تعيين",
  "passenger.vehicle": "مركبة",
  "passenger.vehicles-small": "مركبات",
  "passenger.to-passenger": "للركاب",
  "passenger.auto-save": "حفظ تلقائي في",
  "passenger.select-to-assign": "حدد السيارة لتعيين هذا الراكب",
  "passenger.passengers-clusters": "تجمعات الركاب",

  // 6.Group
  "group.tab-group-vehilces": "المجموعة والمركبات",
  "group.tab-group-list": "قائمة المجموعة",
  "group.group-name": "أسم المجموعة",
  "group.group-description": "وصف المجموعة",
  "group.groups": "مجموعات",
  "group.search-group": "مجموعة البحث",
  "group.name": "اسم",
  "group.details": "تفاصيل",
  "group.action": "عمل",
  "group.user-access": "وصول المستخدم مقيد!",
  "group.remove-driver": "ابعد السائق عن المركبة",
  "group.search-vehicles": "بحث المركبات",
  "group.vehicles": "مركبات",
  "group.select-vehicle": "اختر السيارة",
  "group.assign": "تعيين",
  "group.vehicle": "مركبة",
  "group.vehicles-small": "مركبات",
  "group.select-assign": "حدد السيارة لتعيين هذه المجموعة",
  "group.to-group": "الى المجموعة",
  "group.auto-save": "حفظ تلقائي في",
  "group.group-select": "اختر مجموعة",
  "group.group-not-selected": "المجموعة غير محددة",
  // 7.Favorite Places
  "fav.comming-soon": "قريبا",

  // Support
  "support.records": "تظهر السجلات",
  // 1.Invoices
  "invoice.list": "قائمة الفاتورة",
  "invoice.no": "رقم الفاتورة",
  "invoice.total": "المبلغ الإجمالي",
  "invoice.discount": "خصم",
  "invoice.grand-total": "المجموع الكلي",
  "invoice.date": "تاريخ الفاتورة",
  "invoice-due-date": "تاريخ استحقاق الفاتورة",
  "invoice.status": "الحالة",
  "invoice.type": "نوع الفاتورة",

  // 2.Jobcard
  "jobcard.list": "قائمة بطاقات العمل",
  "jobcard.job": "مهنة",
  "jobcard.ticket": "تذكرة",
  "jobcard.location": "موقعك",
  "jobcard.date": "تاريخ",
  "jobcard.start-time": "وقت البدء",
  "joacard.end-time": "وقت النهاية",
  "jobcard.status": "الحالة",
  "jobcard.chargeable": "تحميل",
  "jobcard.type": "نوع",

  // 3.Service Schedules
  "service.list": "قائمة جداول الخدمة",
  "service.ticket": "تذكرة",
  "service.location": "موقعك",
  "service.date": "تاريخ الجدول الزمني",
  "service.status": "الحالة",
  "service.remarks": "ملاحظات",
  "service.account": "الحساب",

  // 4.Visits
  "visits.list": "قائمة الزيارات",
  "visit.title": "عنوان",
  "visit.date": "تاريخ",
  "visit.location": "موقعك",
  "visit.status": "الحالة",

  // 5.Ticket
  "ticket.list": "قائمة التذاكر",
  "ticket.open-ticket": "تذكرة مفتوحة",
  "ticket.subject": "موضوع",
  "ticket.date": "تاريخ التذكرة",
  "ticket.owner": "صاحب",
  "ticket.status": "الحالة",
  "ticket.priority": "أفضلية",
  "ticket.last_update": "اخر تحديث",
  "ticket.comment-list": "قائمة التعليقات",
  "ticket.view": "عرض التذكرة",
  "ticket.ticket-id": "معرف التذكرة",
  "ticket.add-reply": "إضافة الرد",
  "ticket.open": "افتح",
  "ticket.assigned": "تعيين",
  "ticket.answered": "أجاب",
  "ticket.pending": "قيد الانتظار",
  "ticket.closed": "مغلق",
  "ticket.add-comment": "أضف تعليق",
  "ticket.select": "تحديد",
  "ticket.low": "منخفض",
  "ticket.medium": "متوسط",
  "ticket.high": "عالي",

  // Drill-downs

  "day-summary": "ملخص اليوم",
  "day-summary.search": "حاول البحث عن مرشح أو حدد السيارة",
  "day-summary.nothing": "لا شيء لعرضه",
  "day-summary.summary": "ملخص",
  "day-summary.errands": "المهمات",
  "day-summary.idle": "تسكع",
  "day-summary.over-speed": "فوق السرعات",
  "day-summary.vehicle": "مركبة",
  "day-summary.date": "تاريخ",
  "day-summary.distance": "مسافة",
  "day-summary.enginehrs": "ساعات محرك",
  "day-summary.idlehrs": "ساعات الخمول",
  "day-summary.parkinghrs": "ساعات وقوف السيارات",
  "day-summary.first-start": "البداية الأولى",
  "day-summary.last-end": "آخر نهاية",
  "day-summary.show-less": "عرض أقل",
  "day-summary.show-all": "عرض الكل",
  "day-summary.records": "السجلات",
  "day-summary.day": "يوم",
  "day-summary.time": "زمن",
  "day-summary.total-seconds": "مجموع الثواني",
  "day-summary.limit": "حد",
  "day-summary.speed": "سرعة",
  "day-summary.place": "مكان",
  "day-summary.driver": "سائق",
  "day-summary.start": "بداية",
  "day-summary.end": "النهاية",
  "day-summary.duration": "المدة الزمنية",
  "day-summary.entry": "دخول",
  "geofence-alert": "تنبيهات السياج الجغرافي",
  "geofence.records": "السجلات",

  // Report

  "report.idling-vehicles": "تسكع المركبات",
  "report.total-idling": "إجمالي الخمول",
  "report.freq-idling": "كثرة الخمول",
  "report.longest-idling": "أطول تباطؤ",
  "report.top-freq": "الأكثر تكرارًا",
  "report.count": "العد",
  "report.total": "مجموع",
  "report.avg": "معدل",
  "report.hours": "ساعات",
  "report.time": "مرات",
  "report.top-total": "المدة الإجمالية الأعلى",
  "report.top-longest": "أعلى أطول",
  "report.vehicle": "مركبة",
  "report.place": "مكان",
  "report.driver": "سائق",
  "report.times": "مرات",
  "report.idling": "تسكع",
  "report.try-search": "حاول البحث عن مرشح أو حدد السيارة ",
  "report.nothing-to-show": "نحن جيدون ، لا شيء لإظهاره. جرب مرشح بحث مختلف.",
  "report.zero-vehicle": "تم تحديد صفر مركبة",
  "report.table": "الطاولة",
  "report.group-vehicle": "تجميع حسب السيارة",
  "report.group-driver": "تجميع حسب السائق",
  "report.analytics": "تحليلات",
  "report.records": "السجلات",
  "report.start": "بداية",
  "report.end": "النهاية",
  "report.trips": "رحلات",
  "report.nothing-show": "لا شيء لعرضه",
  "report.start-speed": "بدء السرعة (كم / ساعة)",
  "report.end-speed": "السرعة النهائية (كم / ساعة)",
  "report.rate-of": "معدل التسارع",
  "report.speed-kmph": "السرعة (كم / ساعة)",
  "report.rate-of-turn": "معدل الدوران",
  "report.seconds": "ثواني",
  "report.top-speed": "السرعة القصوى (كم / ساعة)",
  "report.speed-limit": "حد السرعة (كم / ساعة)",
  "report.over-speed": "فوق السرعة",
  "report.hard-breaking": "من الصعب كسر",
  "report.hard-acceleration": "تسريع صعب",
  "report.hard-cornering": "المنعطفات الصعبة",
  "report.alerts": "تنبيهات",
  "report.entry": "دخول",
  "report.geo-alerts": "تنبيهات السياج الجغرافي",
  "report.first-start": "البداية الأولى",
  "report.last-end": "آخر نهاية",
  "report.engine-hrs": "ساعات إنجين",
  "report.vehicle-utlzn": "استخدام السيارة",
  "report.acc-count": "عدد التسارع",
  "report.breaking-count": "عدد الكسور",
  "report.corn-count": "عدد المنعطفات",
  "report.over-speed-count": "عد السرعة الزائدة",
  "report.over-speed-total": "تجاوز السرعة الإجمالية",
  "report.more": "أكثر",
  "report.vehicle-summary": "ملخص السيارة",
  "report.trips-of-vehicle": "رحلات السائق",
  "report.try-search-driver": "حاول البحث في عامل التصفية أو حدد سائق",
  "report.drivers": "السائقين",
  "report.driver-small": "سائق",
  "report.idling-of-driver": "تسكع السائق",
  "report.vehicles": "مركبات",
  "report.driver-utlzn": "استخدام السائق",
  "report.live-status": "الحالة الحية يوم ",
  "report.sort": "فرز",
  "report.web-id": "معرف الويب",
  "report.type": "نوع",
  "report.plate": "طبق",
  "report.asset": "الأصل",
  "report.model": "نموذج",
  "report.cluster": "العنقودية",
  "report.gps": "GPS",
  "report.fuel": "وقود",
  "report.ignition": "اشتعال",
  "report.last-update": "اخر تحديث",
  "report.status": "الحالة",
  "report.id": "ID",
  "report.sim": "SIM",
  "report.sim-no": "SIM NO",
  "report.passenger-alerts": "تنبيهات الركاب",
  "report.passenger": "راكب",
  "report.avg.speed": "متوسط السرعة",
  "report.duration": "المدة الزمنية",
  "report.plate-num":"لوحة لا.",
  "report.fleet-num":"رقم الأسطول",
  "report.verse":"بيت شعر",
  "report.readable":"مقروء",
  "report.log-access-of":"دخول سجل ",
  // Dashboard
  "dashboard.total": "مجموع",
  "dashboard.running": "ادارة",
  "dashboard.idle": "الخمول",
  "dashboard.off": "إيقاف",
  "dashboard.vehicles": "مركبات",
  "dashboard.filter-vehicles": "تصفية المركبات",
  "dashboard.hard-breaking": "من الصعب كسر",
  "dashboard.hard-acc": "تسارع صعب",
  "dashboard.over-speed": "تجاوز السرعة",
  "dashboard.hard-corn": "المنعطفات الصعبة",
  "dashboard.vehicle-utlzn": "استخدام السيارة",
  "dashboard.day": "يوم",
  "dashboard.vehicle-used": "السيارة المستخدمة",
  "dashboard.milage": "المسافة المقطوعة (كم)",
  "dashboard.engn-hrs": "ساعات إنجين",
  "dashboard.idle-hrs": "ساعات الخمول",
  "dashboard.avg.speed":"متوسط السرعة",
  "dashboard.this-month":"هذا الشهر",
  "dashboard.prev-month":"الشهر الماضى",
  "dashboard.next-month":"الشهر القادم",
  "dashboard.geo-summary":"Geofence Summary",
  "dashboard.top-10":"أعلى المركبات عن طريق التنبيهات",
  "dashboard.noting-show":"لا شيء لعرضه",
  "dashboard.please-select-timeline":"حدد سيارة لمعرفة الجدول الزمني",
  // --------------------------------------

  /* 01.General */
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Sign In",
  "user.register": "Register",
  "user.forgot-password": "هل نسيت كلمة المرور",
  "user.email": "البريد الإلكتروني",
  "user.password": "كلمه السر",
  "user.forgot-password-question": "هل نسيت كلمة المرور؟",
  "user.reset-password": "إعادة تعيين كلمة المرور",
  "user.fullname": "Full Name",
  "user.login-button": "تسجيل الدخول",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "إعادة تعيين",
  "user.buy": "BUY",
  "user.username": "Username",
  "user.login-with-your-crednetial":
    "الرجاء استخدام بيانات الاعتماد الخاصة بك لتسجيل الدخول.",
  "user.not-member": "إذا لم تكن عضوا اتصل بنا.",
  "user.by-signin": "بتسجيل الدخول ، فإنك توافق على الشروط والسياسة الخاصة بنا",
  "user.qatar-first": "أول مصممي ومطوري الأجهزة على مستوى الدوائر في قطر.",
  "user.sign-out":"خروج",
  "user.enter-mail": "أدخل بريدك الإلكتروني",
  "user.verify-otp": "تحقق من OTP",
  "user.enter-otp": "أدخل OTP",
  "user.newPassword": "أدخل كلمة مرور جديدة",
  "user.confirmPassword": "تأكيد كلمة المرور",
  "user.please-enter-email-ctraac":"الرجاء إدخال عنوان البريد الإلكتروني المسجل مع Ctraac",
  "user.email-token":"تم إرسال بريد إلكتروني برمز مميز إلى عنوان بريدك الإلكتروني المسجل.",
  "user.email-10min-token":"الرجاء إدخال الرمز المميز في غضون 10 دقائق لإعادة تعيين كلمة المرور الخاصة بك",
  "user.resend-otp-in":"أعد إرسال OTP في",
  "user.resend":"إعادة إرسال",
  
  /* 03.Menu */
  "menu.app": "الهدف",
  "menu.dashboards": "ملخص اليوم",
  "menu.default": "Default",
  "menu.ecommerce": "Ecommerce",
  "menu.content": "Content",
  "menu.pages": "الصفحات",
  "menu.data-list": "Data List",
  "menu.thumb-list": "Thumb List",
  "menu.image-list": "Image List",
  "menu.details": "Details",
  "menu.search": "Search",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.forgot-password": "Forgot Password",
  "menu.error": "Error",
  "menu.applications": "Applications",
  "menu.todo": "To-do List",
  "menu.survey": "Survey",
  "menu.chat": "Chat",
  "menu.ui": "UI",
  "menu.badges": "Badges",
  "menu.buttons": "Buttons",
  "menu.cards": "Cards",
  "menu.carousel": "Carousel",
  "menu.charts": "Charts",
  "menu.collapse": "Collapse",
  "menu.dropdowns": "Dropdowns",
  "menu.editors": "Editors",
  "menu.form-layouts": "Form Layouts",
  "menu.form-components": "Form Components",
  "menu.form-validations": "Form Validations",
  "menu.icons": "Icons",
  "menu.input-groups": "Input Groups",
  "menu.jumbotron": "Jumbotron",
  "menu.modal": "Modal",
  "menu.navigation": "Navigation",
  "menu.popover-tooltip": "Popover & Tooltip",
  "menu.sortable": "Sortable",
  "menu.tables": "Tables",
  "menu.menu": "Menu",
  "menu.subhidden": "Subhidden",
  "menu.hidden": "Hidden",
  "menu.visible": "Visible",
  "menu.maps": "Maps",

  "menu.landingpage": "Landing Page",
  "menu.multipage-home": "Multipage Home",
  "menu.singlepage-home": "Singlepage Home",
  "menu.about": "About",
  "menu.auth-login": "Auth Login",
  "menu.auth-register": "Auth Register",
  "menu.blog": "Blog",
  "menu.blog-detail": "Blog Detail",
  "menu.careers": "Careers",
  "menu.confirmation": "Confirmation",
  "menu.contact": "Contact",
  "menu.docs": "Docs",
  "menu.features": "Features",
  "menu.prices": "Prices",
  "menu.videos": "Videos",
  "menu.mailing": "Mailing",
  "menu.invoice": "Invoice",
  "menu.blank-page": "Blank Page",
  "menu.types": "Menu Types",
  "menu.levels": "Menu Levels",
  "menu.third-level-1": "Third Level 1",
  "menu.third-level-2": "Third Level 2",
  "menu.third-level-3": "Third Level 3",
  /* 04.Dashboards */
  "dashboards.pending-orders": "Pending Orders",
  "dashboards.completed-orders": "Completed Orders",
  "dashboards.refund-requests": "Refund Requests",
  "dashboards.new-comments": "New Comments",
  "dashboards.sales": "Sales",
  "dashboards.orders": "Orders",
  "dashboards.refunds": "Refunds",
  "dashboards.recent-orders": "Recent Orders",
  "dashboards.product-categories": "Product Categories",
  "dashboards.cakes": "Cakes",
  "dashboards.tickets": "Tickets",
  "dashboards.calendar": "Calendar",
  "dashboards.best-sellers": "Best Sellers",
  "dashboards.website-visits": "Website Visits",
  "dashboards.unique-visitors": "Unique Visitors",
  "dashboards.this-week": "This Week",
  "dashboards.last-week": "Lat Week",
  "dashboards.this-month": "This Month",
  "dashboards.conversion-rates": "Conversion Rates",
  "dashboards.per-session": "Per Session",
  "dashboards.profile-status": "Profile Status",
  "dashboards.payment-status": "Payment Status",
  "dashboards.work-progress": "Work Progress",
  "dashboards.tasks-completed": "Tasks Completed",
  "dashboards.payments-done": "Payments Done",
  "dashboards.order-stock": "Order - Stock",
  "dashboards.categories": "Categories",
  "dashboards.quick-post": "Quick Post",
  "dashboards.title": "Title",
  "dashboards.content": "Content",
  "dashboards.category": "Category",
  "dashboards.save-and-publish": "Save and Publish",
  "dashboards.top-viewed-posts": "Top Viewed Posts",
  "dashboards.posts": "Posts",
  "dashboards.pending-for-publish": "Pending for publish",
  "dashboards.users": "Users",
  "dashboards.on-approval-process": "On approval process",
  "dashboards.alerts": "Alerts",
  "dashboards.waiting-for-notice": "Waiting for notice",
  "dashboards.files": "Files",
  "dashboards.pending-for-print": "Pending for print",
  "dashboards.logs": "Logs",
  "dashboards.gogo": "GOGO",
  "dashboards.magic-is-in-the-details": "MAGIC IS IN THE DETAILS",
  "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
  "dashboards.advanced-search": "Advanced Search",
  "dashboards.toppings": "Toppings",
  "dashboards.type": "Type",
  "dashboards.keyword": "Keyword",
  "dashboards.search": "Search",
  "dashboards.top-rated-items": "Top Rated Items",

  /* 05.Pages */
  "pages.add-new": "ADD NEW",
  "pages.add-new-modal-title": "Add New Item",
  "pages.display-options": "Display Options",
  "pages.orderby": "Order By : ",
  "pages.product-name": "Product Name",
  "pages.category": "Category",
  "pages.description": "Description",
  "pages.status": "Status",
  "pages.cancel": "Cancel",
  "pages.submit": "Submit",
  "pages.delete": "Delete",
  "pages.another-action": "Another action",
  "pages.actions": "ACTIONS",
  "pages.header": "Header",
  "pages.details": "DETAILS",
  "pages.orders": "ORDERS",
  "pages.rating": "Rating",
  "pages.price": "Price",
  "pages.ingredients": "Ingredients",
  "pages.is-vegan": "Is Vegan",
  "pages.order-status": "Order Status",
  "pages.bake-progress": "Bake Progress",
  "pages.popularity": "Popularity",
  "pages.comments": "Comments",
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",
  "pages.mailing-info":
    "Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.",
  "pages.invoice-info":
    "Invoice template has an inline styled version for usage outside of the project as well as React version.",
  "pages.react-version": "React Version",
  "pages.inline-version": "Inline Styled Html Version",

  /* 06.Applications */

  /* 06.01.Chat */
  "chat.messages": "Messages",
  "chat.contacts": "Contacts",
  "chat.saysomething": "Say something..",

  /* 06.02.Survey */
  "survey.delete": "Delete",
  "survey.edit": "Edit",
  "survey.add-new": "ADD NEW",
  "survey.add-new-title": "Add New Survey",
  "survey.title": "Title",
  "survey.category": "Category",
  "survey.label": "Label",
  "survey.status": "Status",
  "survey.cancel": "Cancel",
  "survey.submit": "Submit",
  "survey.another-action": "Another action",
  "survey.display-options": "Display Options",
  "survey.orderby": "Order By : ",
  "survey.all-surveys": "All Surveys",
  "survey.completed-surveys": "Completed Surveys",
  "survey.categories": "Categories",
  "survey.active-surveys": "Active Surveys",
  "survey.labels": "Labels",

  /* 06.03.Todo */
  "todo.add-new": "ADD NEW",
  "todo.add-new-title": "Add New Todo",
  "todo.title": "Title",
  "todo.detail": "Detail",
  "todo.category": "Category",
  "todo.label": "Label",
  "todo.status": "Status",
  "todo.cancel": "Cancel",
  "todo.submit": "Submit",
  "todo.action": "Action",
  "todo.another-action": "Another action",
  "todo.display-options": "Display Options",
  "todo.orderby": "Order By : ",
  "todo.all-tasks": "All Tasks",
  "todo.pending-tasks": "Pending Tasks",
  "todo.completed-tasks": "Completed Tasks",
  "todo.categories": "Categories",
  "todo.labels": "Labels",

  /* 07.UI */

  /* 07.01.Alerts */
  "alert.rounded": "Rounded Alert",
  "alert.react-notifications": "React Notifications",
  "alert.outline": "Outline",
  "alert.primary": "Primary",
  "alert.secondary": "Secondary",
  "alert.info": "Info",
  "alert.success": "Success",
  "alert.warning": "Warning",
  "alert.error": "Error",
  "alert.filled": "Filled",
  "alert.primary-text": "This is a primary alert—check it out!",
  "alert.secondary-text": "This is a secondary alert—check it out!",
  "alert.success-text": "This is a success alert—check it out!",
  "alert.danger-text": "This is a danger alert—check it out!",
  "alert.warning-text": "This is a warning alert—check it out!",
  "alert.info-text": "This is a info alert—check it out!",
  "alert.light-text": "This is a light alert—check it out!",
  "alert.dark-text": "This is a dark alert—check it out!",
  "alert.default": "Default Alert",
  "alert.dismissing": "Dismissing Alert",
  "alert.dismissing-text":
    "Holy guacamole! You should check in on some of those fields below.",
  "alert.dismissing-without-animate-text":
    "I am an alert and I can be dismissed without animating!",

  /* 07.02.Badges */
  "badge.sizes": "Sizes",
  "badge.colors": "Colors",
  "badge.outline": "Outline",
  "badge.links": "Links",
  "badge.counter-badges": "Counter Badges",
  "badge.bootstrap-default": "Bootstrap Default",
  "badge.primary": "Primary",
  "badge.secondary": "Secondary",
  "badge.success": "Success",
  "badge.danger": "Danger",
  "badge.warning": "Warning",
  "badge.info": "Info",
  "badge.light": "Light",
  "badge.dark": "Dark",

  /* 07.03.Buttons */
  "button.default": "Bootstrap Default",
  "button.colors": "Colors",
  "button.rounded": "Rounded",
  "button.outline": "Outline",
  "button.states": "States",
  "button.sizes": "Sizes",
  "button.button-groups": "Button Groups",
  "button.large-button": "Large Button",
  "button.small-button": "Small Button",
  "button.extra-small-button": "Extra Small Button",
  "button.block-button": "Block Button",
  "button.active": "Active",
  "button.disabled": "Disabled",
  "button.basic": "Basic",
  "button.toolbar": "Toolbar",
  "button.nesting": "Nesting",
  "button.vertical-variation": "Vertical Variation",
  "button.checkbox-radio-button": "Checkbox and Radio Button",
  "button.checkbox": "Checkbox",
  "button.radio": "Radio",
  "button.radio-button": "Radio Button",
  "button.primary": "Primary",
  "button.secondary": "Secondary",
  "button.success": "Success",
  "button.danger": "Danger",
  "button.warning": "Warning",
  "button.info": "Info",
  "button.light": "Light",
  "button.dark": "Dark",
  "button.states-text":
    "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.click-here": "Click Here",
  "button.states-text-alternate":
    "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.primary-link": "Primary Link",
  "button.link": "Link",
  "button.primary-button": "Primary Button",
  "button.button": "Button",
  "button.left": "Left",
  "button.middle": "Middle",
  "button.right": "Right",
  "button.dropdown": "Dropdown",
  "button.dropdown-link": "Dropdown Link",

  /* 07.04.Cards */
  "cards.icon-card": "Icon Card",
  "cards.image-card": "Image Card",
  "cards.image-overlay-card": "Image Overlay Card",
  "cards.image-card-list": "Image Card List",
  "cards.tab-card": "Tab Card",
  "cards.user-card": "User Card",

  /* 07.05.Carousel */
  "carousel.basic": "Carousel Basic",
  "carousel.single": "Carousel Single",
  "carousel.without-controls": "Carousel Without Controls",

  /* 07.06.Charts */
  "charts.line": "Line Chart",
  "charts.polar": "Polar Chart",
  "charts.area": "Area Chart",
  "charts.scatter": "Scatter Chart",
  "charts.bar": "Bar Chart",
  "charts.radar": "Radar Chart",
  "charts.pie": "Pie Chart",
  "charts.doughnut": "Doughnut Chart",
  "charts.shadow": "Shadow",
  "charts.no-shadow": "No Shadow",

  /* 07.07.Collapse */
  "collapse.basic": "Basic",
  "collapse.toggle": "Toggle",
  "collapse.accordion": "Accordion",
  "collapse.controlled": "Controlled",
  "collapse.uncontrolled": "Uncontrolled",

  /* 07.08.Dropdowns */
  "dropdowns.basic": "Basic",
  "dropdowns.controlled": "Controlled",
  "dropdowns.uncontrolled": "Uncontrolled",
  "dropdowns.dropdown": "Dropdown",
  "dropdowns.header": "Header",
  "dropdowns.action": "Action",
  "dropdowns.another-action": "Another Action",
  "dropdowns.right": "Right",
  "dropdowns.left": "Left",
  "dropdowns.drop-directions": "Drop Directions",
  "dropdowns.dropright": "Dropright",
  "dropdowns.dropleft": "Dropleft",
  "dropdowns.small-button": "Small Button",
  "dropdowns.large-button": "Large Button",
  "dropdowns.sizing": "Sizing",
  "dropdowns.split-button": "Split Button Dropdowns",
  "dropdowns.dropup": "Dropup",

  /* 07.09.Editors */
  "editors.draftjs": "Draft.js",
  "editors.quill-standart": "Quill Standard",
  "editors.quill-bubble": "Quill Bubble",

  /* 07.10.Forms */
  "forms.basic": "Basic",
  "forms.email": "E-mail",
  "forms.email-muted": "We'll never share your email with anyone else.",
  "forms.password": "Password",
  "forms.submit": "Submit",
  "forms.horizontal": "Horizontal",
  "forms.radios": "Radios",
  "forms.first-radio": "First radio",
  "forms.second-radio": "Second radio",
  "forms.third-radio-disabled": "Third disabled radio",
  "forms.checkbox": "Checkbox",
  "forms.signin": "Sign in",
  "forms.top-labels-over-line": "Top Labels Over Line",
  "forms.tags": "Tags",
  "forms.date": "Date",
  "forms.state": "State",
  "forms.top-labels-in-input": "Top Labels In Input",
  "forms.email-u": "E-MAIL",
  "forms.password-u": "PASSWORD",
  "forms.tags-u": "TAGS",
  "forms.date-u": "DATE",
  "forms.state-u": "STATE",
  "forms.grid": "Form Grid",
  "forms.address": "Address",
  "forms.address2": "Address 2",
  "forms.city": "City",
  "forms.city-message": "Please select a city!",
  "forms.state-message": "Please select a state!",
  "forms.zip": "Zip",
  "forms.signup": "Signup",
  "forms.inline": "Inline",
  "forms.validation-availity": "Availity Reactstrap Validation",
  "forms.validation-formik": "Formik Validation",
  "forms.default": "Default",
  "forms.firstname": "First name",
  "forms.firstname-message": "Please enter your first name!",
  "forms.lastname": "Last name",
  "forms.lastname-message": "Please enter your last name!",

  /* 07.11.Form Components */
  "form-components.custom-inputs": "Custom Inputs",
  "form-components.checkboxes": "Checkboxes",
  "form-components.radios": "Radios",
  "form-components.inline": "Inline",
  "form-components.react-select": "React Select",
  "form-components.state-single": "State Single",
  "form-components.state-multiple": "State Multiple",
  "form-components.react-autosuggest": "React Autosuggest",
  "form-components.date-picker": "Date Picker",
  "form-components.date": "Date",
  "form-components.date-range": "Date Range",
  "form-components.date-with-time": "Date with Time",
  "form-components.embedded": "Embedded",
  "form-components.dropzone": "Dropzone",
  "form-components.drop-files-here": "Drop Files Here",
  "form-components.tags": "Tags",
  "form-components.switch": "Switch",
  "form-components.primary": "Primary",
  "form-components.secondary": "Secondary",
  "form-components.primary-inverse": "Primary Inverse",
  "form-components.secondary-inverse": "Secondary Inverse",
  "form-components.slider": "Slider",
  "form-components.double-slider": "Double Slider",
  "form-components.single-slider": "Single Slider",
  "form-components.rating": "Rating",
  "form-components.interactive": "Interactive",
  "form-components.readonly": "Readonly",
  "form-components.type-a-cake": "Type a Cake",
  "form-components.start": "Start",
  "form-components.end": "End",
  "form-components.tables": "Tables",
  /* 07.12.Icons */
  "icons.simplelineicons": "Simple Line Icons",
  "icons.iconsmind": "Iconsmind Icons",

  /* 07.13.Input Groups */
  "input-groups.basic": "Basic",
  "input-groups.sizing": "Sizing",
  "input-groups.small": "Small",
  "input-groups.default": "Default",
  "input-groups.large": "Large",
  "input-groups.checkboxes-and-radios": "Checkboxes and radios",
  "input-groups.multiple-inputs": "Multiple Inputs",
  "input-groups.first-and-last-name": "First and last name",
  "input-groups.multiple-addons": "Multiple Addons",
  "input-groups.button-addons": "Button Addons",
  "input-groups.button": "Button",
  "input-groups.buttons-with-dropdowns": "Buttons with Dropdowns",
  "input-groups.dropdown": "Dropdown",
  "input-groups.header": "Header",
  "input-groups.action": "Action",
  "input-groups.another-action": "Another Action",
  "input-groups.custom-select": "Custom Select",
  "input-groups.options": "Options",
  "input-groups.choose": "Choose...",
  "input-groups.custom-file-input": "Custom File Input",

  /* 07.14.Jumbotron */
  "jumbotron.hello-world": "Hello, world!",
  "jumbotron.lead":
    "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
  "jumbotron.lead-detail":
    "It uses utility classes for typography and spacing to space content out within the larger container.",
  "jumbotron.learn-more": "Learn more",

  /* 07.15.Modal */
  "modal.basic": "Basic",
  "modal.modal-title": "Modal title",
  "modal.launch-demo-modal": "Launch Demo Modal",
  "modal.scrolling-long-content": "Scrolling Long Content",
  "modal.backdrop": "Backdrop",
  "modal.backdrop-value": "Backdrop value",
  "modal.right-modal": "Right Modal",
  "modal.launch-right-modal": "Launch Right Modal",
  "modal.nested-modal": "Nested Modal",
  "modal.size": "Size",
  "modal.launch-large-modal": "Launch Large Modal",
  "modal.launch-small-modal": "Launch Small Modal",

  /* 07.16.Navigation */
  "nav.basic": "Nav Basic",
  "nav.active": "Active",
  "nav.disabled": "Disabled",
  "nav.disabled-link": "Disabled Link",
  "nav.link": "Link",
  "nav.longer-link": "Longer nav link",
  "nav.another-link": "Another Link",
  "nav.right": "Right",
  "nav.dropdown": "Dropdown",
  "nav.header": "Header",
  "nav.action": "Action",
  "nav.another-action": "Another Action",
  "nav.horizontal-alignment": "Nav Horizontal Alignment",
  "nav.vertical-alignment": "Nav Vertical Alignment",
  "nav.pills": "Nav Pills",
  "nav.fill-justify": "Nav Fill and Justify",
  "nav.pills-dropdowns": "Nav Pills with Dropdowns",
  "nav.pagination-basic": "Pagination Basic",
  "nav.pagination-sizing": "Pagination Sizing",
  "nav.large": "Large",
  "nav.small": "Small",
  "nav.center": "Center",
  "nav.pagination-alignment": "Pagination Alignment",
  "nav.breadcrumb-basic": "Breadcrumb Basic",

  /* 07.17.Popover & Tooltip */
  "popover-tooltip.popover": "Popover",
  "popover-tooltip.tooltip": "Tooltip",

  /* 07.18.Sortable */
  "sortable.columns": "Sorting Columns",
  "sortable.basic": "Basic",
  "sortable.handles": "Handles",

  /* 07.19.Maps */
  "maps.google": "Google",
  "maps.yandex": "Yandex",

  /* 07.20.Tables */
  "table.bootstrap-tables": "Bootstrap Tables",
  "table.bootstrap-basic": "Basic Table",
  "table.bootstrap-striped": "Striped Rows",
  "table.bootstrap-bordered": "Bordered Table",
  "table.bootstrap-borderless": "Borderless Table",
  "table.bootstrap-hoverable": "Hoverable Rows",
  "table.bootstrap-responsive": "Responsive Table",
  "table.react-tables": "React Tables",
  "table.react-pagination": "Pagination",
  "table.react-scrollable": "Scrollable",
  "table.react-advanced": "Filter, Length and Jump",
};
export default arData;
