import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../../redux/actions";
import IntlMessages from "../../../helpers/IntlMessages";

class VehiclesContainer extends Component {
  constructor(props) {
    super();
    this.state = {
       
    };
  }

  render() {
    
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
               
              <CardBody>
                <CardTitle>
                  <IntlMessages id="tracking.vehicle-list" />
                </CardTitle>
                 
                <div className="card-body-min-height">
                   card body
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user, };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  VehiclesContainer
);
