/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
*/

const englishObject = {
  // Menu
  "menu.support": "Support",
  "menu.invoices": "Invoices",
  "menu.jobcards": "Jobcards",
  "menu.services": "Service Schedules",
  "menu.tickets": "Tickets",
  "menu.visits": "Visits",

  "menu.settings": "Settings",
  "menu.vehicle-master": "Vehicle Master",
  "menu.user-master": "User Master",
  "menu.profile-master": "Profile",
  "menu.company-master": "Profile",
  "menu.live": "Live",
  "menu.passenger": "Passengers",
  "menu.passenger-view": "Passenger View",
  "menu.passenger-changes": "Passenger Changes",

  "menu.reports": "Reports",
  "menu.reports2": "Reports2",
  "menu.idle": "Idle Report",
  "menu.trip": "Trip Report",
  "menu.fuel": "Fuel Report",
  "menu.alert": "Alert Report",
  "menu.geofence": "Geofence",
  "menu.geofence-alerts": "Geofence Alerts",
  "menu.geofence-settings": "Geofence Settings",
  "menu.restricted-geofence": "Restricted Geofence",
  "menu.event": "Event Report",
  "menu.vehicle": "Vehicle Report",
  "menu.driver": "Driver Report",
  "menu.driver-utilization": "Driver Utilization",
  "menu.vehicle-utilization": "Vehicle Utilization",
  "menu.driver-ranking": "Driver Ranking",
  "menu.vehicle-summary": "Vehicle Summary",
  "menu.driver-log": "Driver Logs",
  "menu.driver-trips": "Driver Trips",
  "menu.driver-alerts": "Driver Alerts",
  "menu.driver-idling": "Driver Idling",
  "menu.driver-settings": "Driver Settings",
  "menu.driver-view": "Driver View",

  "menu.alerts": "Alerts",
  "menu.story": "Story",
  "menu.groups": "Groups",

  "menu.vehicles": "Vehicles",
  "menu.logistics": "Logistics",
  "menu.vehicle-view": "Vehicle View",
  "menu.live-map": "Live Map",
  "menu.history": "History",
  "menu.multiple-history": "Multiple History",
  "menu.geofence-history": "Geofence History",
  "menu.geofence-analysis": "Geofence Analysis",
  "menu.trips": "Trips",
  "menu.trip-view": "Trip View",
  "menu.speed-chart": "Speed Chart",
  "menu.speed-chart-2":"Speed Chart 2",
  "menu.fuel-chart": "Fuel Chart",
  "menu.efficiency": "Efficiency",
  "menu.map-search": "Map Search",
  "menu.favorite-places": "Favorite Places",
  "menu.log-access": "Log Access",

  "menu.analytics": "Analytics",
  "menu.drill-downs": "Drill Downs",
  "menu.drill-downs-geofence": "Geofence",
  "menu.drill-downs-day-summary": "Day Summary",

  // --------------------------------------

  /** Common */
  "common.confirm": "Confirm?",
  "common.cancel": "Cancel",
  "common.submit": "Submit",
  "common.update": "Update",
  "common.save": "Save",
  "common.selected": "Selected",
  "common.clear": "Clear",
  "common.select-all": "Select All",
  "common.select-by": "Select By",
  "common.records-showing": "Records showing",
  "common.view": "View",
  "common.select-vehicles": "Select Vehicles",
  "common.total": "Total",
  "common.running": "Running",
  "common.idle": "Idle",
  "common.off": "Off",
  "common.no-data": "No Data",
  "common.geofence": "Geofence",
  "common.select-vehicle": "Select Vehicle",
  "common.error": "ERROR",
  "common.download": "Download",
  "common.vehicle": "Vehicle",
  "common.search": "Search...",
  "common.time": "Time",
  "common.refresh": "Refresh",
  "common.redo-list": "Redo Listed",
  "common.redo": "Redo",
  "common.close": "Close",

  /** Tracking Menu */
  "tracking.vehicle-list": "Vehicle List",
  "tracking.search-vehicles": "Search Vehicles",
  "tracking.web-id": "Web id",
  "tracking.type": "Type",
  "tracking.plate": "Plate",
  "tracking.asset": "Asset",
  "tracking.cluster": "Cluster",
  "tracking.model": "Model",
  "tracking.year": "Year",
  "tracking.selected": "Selected",
  "tracking.filter-vehicles": "Filter vehicles",
  "tracking.voice-enabled": "voice-enabled",
  "tracking.please-select-vehicle": "please select vehicle",
  "tracking.more": "More",
  "tracking.day": "day",
  "tracking.today": "Today",
  "tracking.go": "Go",
  "tracking.print-this-out": "Print this out",
  "tracking.speed": "Speed",
  "tracking.events": "Events",
  "tracking.play": "Play",
  "tracking.focus": "Focus",
  "tracking.slower": "Slower",
  "tracking.faster": "Faster",
  "tracking.reset": "Reset",
  "tracking.stop": "Stop",
  "tracking.speed-zero-less-than-5-min-but-more-than-2-min":
    "Speed zero less than 5 min but more than 2 min",
  "tracking.speed-zero-more-than-5-min": "Speed zero more than 5 min",
  "tracking.distance": "Distance",
  "tracking.km": "km",
  "tracking.show-all": "Show All",
  "tracking.hide-all": "Hide All",
  "tracking.refresh": "Refresh",
  "tracking.month": "Month",
  "tracking.trips-of-vehicle": "Trips of vehicle",
  "tracking.from": "from",
  "tracking.to": "to",
  "tracking.try-search-filter-or-select-vehicle":
    "Try search filter or select vehicle",
  "tracking.nothing-to-show": "Nothing to show",
  "tracking.prev": "Prev",
  "tracking.next": "Next",
  "tracking.showing": "Showing",
  "tracking.records": "records",
  "tracking.start": "Start",
  "tracking.end": "End",
  "tracking.HH:mm": "HH:mm",
  "tracking.driver": "Driver",
  "tracking.trips": "Trips",
  "tracking.live": "Live",
  "tracking.history": "History",
  "tracking.engin": "Engin",
  "tracking.on": "On",
  "tracking.off": "Off",
  "tracking.kmph": "kmph",
  "tracking.fuel": "Fuel",
  "tracking.no-data-available": "No data available",
  "tracking.day-summary": "Day Summary",
  "tracking.table": "Table",
  "tracking.total-trips": "Total Trips",
  "tracking.place": "Place",
  "tracking.entry": "Entry",
  "tracking.geofence-alerts": "Geofence Alerts",
  "tracking.speed-chart": "Speed Chart",
  "tracking.geo-point": "Geo point (lat,lng)",
  "tracking.radius": "Radius (in meters)",
  "tracking.choose-maximum-one-week":
    "Choose maximum one week and 10 vehicles for best perfomance",
  "tracking.building-no": "Building No",
  "tracking.blue-plate-building-no": "Blue plate building no",
  "tracking.street": "Street",
  "tracking.blue-plate-street-no": "Blue plate street no",
  "tracking.zone": "Zone",
  "tracking.blue-plate-zone-no": "Blue plate zone no",
  "tracking.find": "Find",
  "tracking.marker": "Marker",
  "tracking.diff": "Diff",

  /**error */
  "error.magic-isin-the-details": "MAGIC IS IN THE DETAILS",
  "error.yes-it-is-needed": "Yes,it is needed!",
  "error.title": "Ooops... looks like an error occurred!",
  "error.code": "Error code",
  "error.go-back-home": "GO BACK HOME",
  //Settings

  //1.Driver Settings
  "driver.reader-type": "Reader Type",
  "driver.rfid": "RFID Code",
  "driver.emp": "Emp Code",
  "driver.employee-code": "Employee Code",
  "driver.driver-name": "Driver name",
  "driver.name": "Name",
  "driver.drivers": "Drivers",
  "driver.search-drivers": "Search Drivers",
  "driver.search-vehicles": "Search Vehicles",
  "driver.assigned": "Assigned vehicles",
  "driver.action": "Action",
  "driver.view": "View",
  "driver.pending": "Pending",
  "driver.driver-view": "Driver View",
  "driver.delete": "Delete Driver",
  "driver.confirm-delete": "Confirm delete driver?",
  "driver.vehicles-of": "Vehicles of Driver",
  "driver.please-select-vehicle": "Please select vehicles to assign",
  "driver.please-select-driver": "Please select driver",
  "driver.assign-driver": "Please select driver names to assign to vehicle",
  "driver.assign-vehicle": "Please select vehicles",
  "driver.select-vehicle": "Select vehicle",
  "driver.redoList": "Redo Listed",
  "driver.vehicles": "Vehicles",
  "driver.status": "Status",
  "driver.refresh": "Refresh",
  "driver.redo": "Redo",
  "driver.active": "Active",
  "driver.remove": "Remove",
  "driver.re-apply": "Re-apply driver to the vehicle again",
  "driver.remove-driver": "Remove driver from vehicle",
  "driver.confirm": "Confirm",
  "driver.select-vehicle-assign": "Select vehicle to assign this driver",
  "driver.assign": "Assign",
  "driver.vehicle": "vehicle",
  "driver.to-driver": "to driver",
  "driver.to-vehicle": "to vehicle",

  // 2.Vehicle Settings
  "vehicles.info": "Info",
  "vehicle.info-text":
    "Any changes made here will schedule in communication channel to update locally in next possible exchange. Any success indicate just communication channel changes initially. And changes you can see in Settings -> Vehicle Master -> Vehicle Settings -> Controls, for each vehicles separately",
  "vehicle.general": "General Controls",
  "vehicle.over-speed": "Over speed",
  "vehicle.angle-threshold": "Cornering Angle Threshold",
  "vehicle.speed-threshold": "Cornering Speed Threshold",
  "vehicle.break-threshold": "Hard Breaking Threshold",
  "vehicle.acc-threshold": "Hard Accelerating Threshold",
  "vehicle.idle-sec": "Idling Threshold (in sec)",
  "vehicle.idle-kmph": "Idling Exit kmph",
  "vehicle.read-command": "Read Commands",
  "vehicle.read-over": "Read over speed",
  "vehicle.fuel-volume": "Read Fuel Tank Volume",
  "vehicle.read-angle": "Read Cornering Angle",
  "vehicle.read-speed": "Read Cornering Speed",
  "vehicle.read-break": "Read Hard Breaking",
  "vehicle.read-acc": "Read Hard Acceleration",
  "vehicle.read-idle": "Read Idling sec",
  "vehicle.read-buz-status": "Read Buzzer Status",
  "vehicle.read-imob-status": "Read Immobilization Status",
  "vehicle.read-peripheral-1": "Read Peripheral AUX1",
  "vehicle.read-peripheral-2": "Read Peripheral AUX2",
  "vehicle.read-expiry": "Read Expiry",
  "vehicle.read-apn": "Read APN",
  "vehicle.read-odo": "Read ODO",
  "vehicle.read-opt-hrs": "Read Operational Hours",
  "vehicle.get-trip": "Get Trip History Data",
  "vehicle.read": "Read",
  "vehicle.get-event": "Get Event History Data",
  "vehicle.sensitive-commands": "Sensitive Commands",
  "vehicle.mob-temp": "Mobilization temporary",
  "vehicle.disable": "Disable",
  "vehicle.idle-buzz": "Idling Buzzer",
  "vehicle.off": "OFF",
  "vehicle.on": "ON",
  "vehicle.alert-buzz": "Alert Buzzer",
  "vehicle.imob": "Immobilization",
  "vehicle.assign-aux": "Assign peripheral AUX ",
  "vehicle.none": "NONE",
  "vehicle.driver-rfid": "Driver RFID",
  "vehicle.please-select-vehicle": "Please select vehicle to update",
  "vehicle.you-are-update": "You are about to update",
  "vehicle.tooltip-best-value-7":
    "The best value is between 5 and 15, default is 7",
  "vehicle.tooltip-best-value-35":
    "The best value is between 25 and 50, default is 35",
  "vehicle.tooltip-best-value-10":
    "The best value is between 7 and 15, default is 10",
  "vehicle.tooltip-best-value-9":
    "The best value is between 7 and 15, default is 9",
  "vehicle.tooltip-best-value-600":
    "The best value is between 600 and 2400, default is 600sec",
  "vehicle.tooltip-best-value-120":
    "The best value is between 20 and 360, default is 120",
  "vehicle.tooltip-best-value-10kmph":
    "The best value is between 0 and 10, default is 10kmph",
  "vehicle.vehicle-setting-tab": "Vehicle Settings",
  "vehicle.common-setting-tab": "Common Settings",
  "vehicle.read-controls": "Read Controls",
  "vehicle.controls": " Vehicle Controls",
  "vehicle.type": "Type",
  "vehicle.status": "Status",
  "vehicle.value": "Value",
  "vehicle.action": "Action",
  "vehicle.no": "NO",
  "vehicle.jt": "JT",
  "vehicle.rcs": "RCS",
  "vehicle.current": "Current Value is",
  "vehicle.default": "Default is ON",
  "vehicle.selected-vehicle": "Select Vehicle",
  "vehicle.drivers-of": "Drivers of vehicle",
  "vehicle.details": "Details",
  "vehicle.tab-controls": "Controls",
  "vehicle.drivers": "Drivers",
  "vehicle.select-continue": "Please select a vehicle to continue",
  "vehicle.redo-list": "Redo Listed",
  "vehicle.redo": "Redo",
  "vehicle.name": "Name",
  "vehicle.emp": "Emp code",
  "vehicle.re-apply": "Re-apply drivers to the vehicle again",
  "vehicle.confirm": "Confirm",
  "vehicle.drivers-?": "drivers ?",
  "vehicle.remove": "Remove driver from vehicle",
  "vehicle.read-controls-device": "Read controls value from device",
  "vehicle.select-driver": "Select Driver",
  "vehicle.edit": "Vehicle Details Edit",
  "vehicle.not-select": "Vehicle not selected",
  "vehicle.title": "Vehicle Title",
  "vehicle.plate-no": "Plate Number",
  "vehicle.asset-no": "Asset Number",
  "vehicle.vehicle-type": "Vehicle Type",
  "vehicle.manfr-name": "Manufacture Name",
  "vehicle.modal": "Model",
  "vehicle.tyre": "Tyre",
  "vehicle.fuel-type": "Fuel Type",
  "vehicle.engine": "Engine",
  "vehicle.year": "Year",
  "vehicle.sim-no": "SIM Number",
  "vehicle.sim-provider": "SIM Provider",
  "vehicle.cluster": "Cluster",
  "vehicle.device-id": "Device ID",

  // 3.Profile

  "profile.company-logo": "Company Logo",
  "profile.change": "Change",
  "profile.details-edit": "Details Edit",
  "profile.cmpny-name": "Company Name",
  "profile.attribute-edit": "Attributes Edit",
  "profile.section": "Section",
  "profile.attribute": "Attributes",
  "profile.value": "Value",
  "profile.live-map": "Live Map",
  "profile.no-data": "No Data Time",

  // 4.User

  "user.executive-from": "Executive Form",
  "user.user-role": "User Role",
  "user.user-email": "Email",
  "user.new-password": "New Password",
  "user.first-name": "First Name",
  "user.last-name": "Last Name",
  "user.nick-name": "Nick Name ",
  "user.contact-no": "Contact Number",
  "user.exe-list": "Executive List",
  "user.user-access": "User Access Restricted!",
  "user.user-status": "Status",
  "user.user-name": "User Name",
  "user.group": "Group",

  // 5.Passengers
  "passenger.tab-list": "Passenger List",
  "passenger.tab-cluster": "Passenger Cluster",
  "passenger.passenger-name": "Passenger Name",
  "passenger.passenger-code": "Passenger Code",
  "passenger.passenger-rfid": "Passenger RFID",
  "passenger.passengers": "Passengers",
  "passenger.search-passenger": "Search Passengers",
  "passenger.cluster": "cluster",
  "passenger.name": "Name",
  "passenger.emp": "Emp Code",
  "passenger.rfid": "RFID",
  "passenger.Action": "Action",
  "passenger.view": "View",
  "passenger.passenger-view": "Passenger View",
  "passenger.clusters": " Passenger Clusters",
  "passenger.vehicles": "Vehicles",
  "passenger.status": "Status",
  "passenger.re-apply": "Re-apply passengers to the vehicle again",
  "passenger.delete": " Delete Passenger",
  "passenger.confirm": "Confirm delete?",
  "passenger.vehicles-of": "Vehicles of Passenger",
  "passenger.vehicles-to": "Vehicles to assign",
  "passenger.search-vehicles": "Search Vehicles",
  "passenger.re-apply-drivers": "Re-apply drivers to the vehicle again",
  "passenger.confirm-passenger": "Confirm",
  "passenger.passengers-?": "passengers?",
  "passenger.re-apply-passengers": "Re-apply passenger to the vehicle again",
  "passenger.remove": "Remove passenger from vehicle",
  "passenger.selct-vehicle": "Select vehicle",
  "passenger.assign": "Assign",
  "passenger.vehicle": "vehicle",
  "passenger.vehicles-small": "vehicles",
  "passenger.to-passenger": "to passenger",
  "passenger.auto-save": "auto save in",
  "passenger.select-to-assign": "Select vehicle to assign this passenger",
  "passenger.passengers-clusters": "The passengers clusters",

  // 6.Group
  "group.tab-group-vehilces": "Group & Vehicles",
  "group.tab-group-list": "Group List",
  "group.group-name": "Group Name",
  "group.group-description": "Group Description",
  "group.groups": "Groups",
  "group.search-group": "Search Group",
  "group.name": "Name",
  "group.details": "Details",
  "group.action": "Action",
  "group.user-access": "User Access Restricted!",
  "group.remove-driver": "Remove driver from vehicle",
  "group.search-vehicles": "Search Vehicles",
  "group.selct-vehicle": "Select vehicle",
  "group.vehicles": "Vehicles",
  "group.select-vehicle": "Select vehicle",
  "group.assign": "Assign",
  "group.vehicle": "vehicle",
  "group.vehicles-small": "vehicles",
  "group.select-assign": "Select vehicle to assign this group",
  "group.to-group": "to group",
  "group.auto-save": "auto save in",
  "group.group-select": "Select Group",
  "group.group-not-selected": "Group Not Selected",
  
  // 7.Favorite Places
  "fav.comming-soon": "Comming soon",

  // Support
  "support.records": "Records showing",
  // 1.Invoices
  "invoice.list": "Invoice List",
  "invoice.no": "Inv.No.",
  "invoice.total": "Total Amount",
  "invoice.discount": "Discount",
  "invoice.grand-total": "Grand Total",
  "invoice.date": "Invoice Date",
  "invoice-due-date": "Invoice Due Date",
  "invoice.status": "Status",
  "invoice.type": "Invoice Type",
  // 2.Jobcard
  "jobcard.list": "Jobcard List",
  "jobcard.job": "Job",
  "jobcard.ticket": "Ticket",
  "jobcard.location": "Location",
  "jobcard.date": "Date",
  "jobcard.start-time": "Start Time",
  "joacard.end-time": "End Time",
  "jobcard.status": "Status",
  "jobcard.chargeable": "Chargeable",
  "jobcard.type": "Type",

  // 3.Service Schedules
  "service.list": "Service Schedules List",
  "service.ticket": "Ticket",
  "service.location": "Location",
  "service.date": "Schedule Date",
  "service.status": "Status",
  "service.remarks": "Remarks",
  "service.account": "Account",

  // 4.Visits
  "visits.list": "Visits List",
  "visit.title": "Title",
  "visit.date": "Date",
  "visit.location": "Location",
  "visit.status": "Status",

  // 5.Ticket
  "ticket.list": "Ticket List",
  "ticket.open-ticket": "Open Ticket",
  "ticket.subject": "Subject",
  "ticket.date": "Ticket Date",
  "ticket.owner": "Owner",
  "ticket.status": "Status",
  "ticket.priority": "Priority",
  "ticket.last_update": "Last Update",
  "ticket.comment-list": "Comment List",
  "ticket.view": "View Ticket",
  "ticket.ticket-id": "Ticket ID",
  "ticket.add-reply": "Add Reply",
  "ticket.open": "Open",
  "ticket.assigned": "Assigned",
  "ticket.answered": "Answered",
  "ticket.pending": "Pending",
  "ticket.closed": "Closed",
  "ticket.add-comment": "Add Comment",
  "ticket.select": "Select",
  "ticket.low": "Low",
  "ticket.medium": "Medium",
  "ticket.high": "High",

  // drill-downs
  // 1.Day Summary
  "day-summary": "Day Summary",
  "day-summary.search": "Try search filter or select vehicle",
  "day-summary.nothing": "Nothing to show",
  "day-summary.summary": "Summary",
  "day-summary.errands": "Errands",
  "day-summary.idle": "Idling",
  "day-summary.over-speed": "Over speeds",
  "day-summary.vehicle": "Vehicle",
  "day-summary.date": "Date",
  "day-summary.distance": "Distance",
  "day-summary.enginehrs": "EnginHours",
  "day-summary.idlehrs": "IdleHours",
  "day-summary.parkinghrs": "ParkingHours",
  "day-summary.first-start": "First Start",
  "day-summary.last-end": "Last End",
  "day-summary.show-less": "Show less",
  "day-summary.show-all": "Show all",
  "day-summary.records": "records",
  "day-summary.day": "Day",
  "day-summary.time": "Time",
  "day-summary.total-seconds": "Total Seconds",
  "day-summary.limit": "Limit",
  "day-summary.speed": "Speed",
  "day-summary.place": "Place",
  "day-summary.driver": "Driver",
  "day-summary.start": "Start",
  "day-summary.end": "End",
  "day-summary.duration": "Duration",
  "day-summary.entry": "Entry",
  "geofence-alert": "Geofence Alerts",
  "geofence.records": " Records",
  "geofence.geofence-not-selected": "Geofence Not Selected",
  "geofence.geofence-select": "Select Geofence",
  "geofence.tab-geofence-vehilces": "Geofence & Vehicles",
  "geofence.to-group": "to geofence",
 

  // Report

  "report.idling-vehicles": "Idling vehicles",
  "report.total-idling": "Total idling",
  "report.freq-idling": "Frequent idling",
  "report.longest-idling": "Longest idling",
  "report.top-freq": "Top Frequent",
  "report.count": "Count",
  "report.total": "Total",
  "report.avg": "Avg.",
  "report.hours": "Hours",
  "report.top-total": "Top Total Duration",
  "report.top-longest": "Top Longest",
  "report.vehicle": "Vehicle",
  "report.place": "Place",
  "report.driver": "Driver",
  "report.show-all": "Show all",
  "report.rows": "rows",
  "report.times": "Times",
  "report.idling": "Idling",
  "report.try-search": "Try search filter or select vehicle ",
  "report.nothing-to-show":
    "We are good, nothing to show. try different search filter.",
  "report.zero-vehicle": "zero vehicle selected",
  "report.table": "Table",
  "report.group-vehicle": "Group by vehicle",
  "report.group-driver": "Group by driver",
  "report.analytics": "Analytics",
  "report.records": "Records",
  "report.start": "Start",
  "report.end": "End",
  "report.trips": "Trips",
  "report.overspeed": "Overspeed",
  "report.summary": "Summary",
  "report.nothing-show": "Nothing to show",
  "report.start-speed": "Start Speed(kmph)",
  "report.end-speed": "End Speed(kmph)",
  "report.rate-of": "Rate Of Acceleration",
  "report.speed-kmph": "Speed(kmph)",
  "report.rate-of-turn": "Rate Of Turn",
  "report.seconds": "Seconds",
  "report.top-speed": "Top Speed(kmph)",
  "report.speed-limit": "Speed Limit(kmph)",
  "report.over-speed": "Over Speed",
  "report.hard-breaking": "Hard Breaking",
  "report.hard-acceleration": "Hard Acceleration",
  "report.hard-cornering": "Hard Cornering",
  "report.alerts": "Alerts",
  "report.entry": "Entry",
  "report.geo-alerts": "Geofence Alerts",
  "report.first-start": "First Start",
  "report.last-end": "Last End",
  "report.engine-hrs": "Engin Hours",
  "report.vehicle-utlzn": "Vehicle Utilization",
  "report.acc-count": "Acceleration count",
  "report.breaking-count": "Breakings count",
  "report.corn-count": "Cornering count",
  "report.over-speed-count": "Over speed count",
  "report.over-speed-total": "Over speed total",
  "report.more": "More",
  "report.vehicle-summary": "Vehicle Summary",
  "report.trips-of-vehicle": "Trips of driver",
  "report.try-search-driver": "Try search filter or select driver",
  "report.drivers": "Drivers",
  "report.driver-small": "Driver",
  "report.idling-of-driver": "Idling of driver",
  "report.vehicles": "Vehicles",
  "report.driver-utlzn": "Driver Utilization",
  "report.live-status": " Live status on",
  "report.sort": "Sort",
  "report.web-id": "Web id",
  "report.type": "Type",
  "report.plate": "Plate",
  "report.asset": "Asset",
  "report.model": "Model",
  "report.cluster": "Cluster",
  "report.gps": "GPS",
  "report.fuel": "Fuel",
  "report.ignition": "Ignition",
  "report.last-update": "Last Update",
  "report.status": "Status",
  "report.id": "ID",
  "report.sim": "SIM",
  "report.sim-no": "SIM NO",
  "report.passenger-alerts": "Passenger Alerts",
  "report.passenger": "Passenger",
  "report.avg.speed": "Avg Speed",
  "report.duration": "Duration",
  "report.plate-num": "Plate No.",
  "report.fleet-num": "Fleet No.",
  "report.time":"Time",
  "report.verse":"Verse",
  "report.readable":"Readable",
  "report.log-access-of":"Log access of ",
  // Dashboard
  "dashboard.total": "Total",
  "dashboard.running": "Running",
  "dashboard.idle": "Idle",
  "dashboard.off": "Off",
  "dashboard.vehicles": "Vehicles",
  "dashboard.filter-vehicles": "Filter Vehicles",
  "dashboard.hard-breaking": "Hard breaking",
  "dashboard.hard-acc": "Hard acceleration",
  "dashboard.over-speed": "Over speeding",
  "dashboard.hard-corn": "Hard cornering",
  "dashboard.vehicle-utlzn": "Vehicle utilization",
  "dashboard.day": "Day",
  "dashboard.vehicle-used": "Vehicle used",
  "dashboard.milage": " Mileage (km) ",
  "dashboard.engn-hrs": " Engin hours ",
  "dashboard.idle-hrs": " Idle hours ",
  "dashboard.avg.speed": "Average speed",
  "dashboard.this-month": "This Month",
  "dashboard.prev-month": "Previous Month",
  "dashboard.next-month": "Next Month",
  "dashboard.geo-summary": "Geofence Summary",
  "dashboard.top-10": "Top vehicles by alerts",
  "dashboard.noting-show": "Nothing to show",
  "dashboard.please-select-timeline": "Select a vehicle to see timeline",
  // --------------------------------------

  /* 01.General */
  "general.copyright": "Cloud Telematics © 2019 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Sign In",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.forgot-password-question": "Forgot password?",
  "user.reset-password": "Reset Password",
  "user.fullname": "Full Name",
  "user.login-button": "Sign In",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "Username",
  "user.login-with-your-crednetial": "Please use your credentials to login.",
  "user.not-member": "If you are not a member contact us. Mob +974 66748329",
  "user.by-signin": "By Signing In, you agree to our Terms & Policy",
  "user.qatar-first":
    "Qatar's first circuit level hardware designers and developers.",
  "user.enter-mail": "Enter your email",
  "user.sign-out": "Sign out",
  "user.verify-otp": "Verify OTP",
  "user.enter-otp": "Enter OTP",
  "user.newPassword": "Enter New Password",
  "user.confirmPassword": "Confirm Password",
  "user.please-enter-email-ctraac":
    "Please enter the email address registered with Ctraac",
  "user.email-token":
    "An email with a token has been sent to your registered email address.",
  "user.email-10min-token":
    "Please enter the token within 15 minutes to reset your password",
  "user.resend-otp-in": "Resend OTP in ",
  "user.resend": "Resend",
  /* 03.Menu */
  "menu.overspeed": "Overspeed",
  "menu.summary": "Summary",
  "menu.app": "Home",
  "menu.dashboards": "Dashboards",
  "menu.default": "Default",

  "menu.ecommerce": "Ecommerce",
  "menu.content": "Content",
  "menu.pages": "Tracking",
  "menu.tracking": "Tracking",
  "menu.data-list": "Data List",
  "menu.thumb-list": "Thumb List",
  "menu.image-list": "Image List",
  "menu.details": "Details",
  "menu.search": "Search",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.forgot-password": "Forgot Password",
  "menu.error": "Error",
  "menu.applications": "Applications",
  "menu.todo": "To-do List",
  "menu.survey": "Survey",
  "menu.chat": "Chat",
  "menu.ui": "UI",
  "menu.badges": "Badges",
  "menu.buttons": "Buttons",
  "menu.cards": "Cards",
  "menu.carousel": "Carousel",
  "menu.charts": "Charts",
  "menu.collapse": "Collapse",
  "menu.dropdowns": "Dropdowns",
  "menu.editors": "Editors",
  "menu.form-layouts": "Form Layouts",
  "menu.form-components": "Form Components",
  "menu.form-validations": "Form Validations",
  "menu.icons": "Icons",
  "menu.input-groups": "Input Groups",
  "menu.jumbotron": "Jumbotron",
  "menu.modal": "Modal",
  "menu.navigation": "Navigation",
  "menu.popover-tooltip": "Popover & Tooltip",
  "menu.sortable": "Sortable",
  "menu.tables": "Tables",
  "menu.menu": "Menu",
  "menu.subhidden": "Subhidden",
  "menu.hidden": "Hidden",
  "menu.visible": "Visible",
  "menu.maps": "Maps",

  "menu.landingpage": "Landing Page",
  "menu.multipage-home": "Multipage Home",
  "menu.singlepage-home": "Singlepage Home",
  "menu.about": "About",
  "menu.auth-login": "Auth Login",
  "menu.auth-register": "Auth Register",
  "menu.blog": "Blog",
  "menu.blog-detail": "Blog Detail",
  "menu.careers": "Careers",
  "menu.confirmation": "Confirmation",
  "menu.contact": "Contact",
  "menu.docs": "Docs",
  "menu.features": "Features",
  "menu.prices": "Prices",
  "menu.videos": "Videos",
  "menu.mailing": "Mailing",
  "menu.invoice": "Invoice",
  "menu.blank-page": "Blank Page",
  "menu.types": "Menu Types",
  "menu.levels": "Menu Levels",
  "menu.third-level-1": "Third Level 1",
  "menu.third-level-2": "Third Level 2",
  "menu.third-level-3": "Third Level 3",

  /* 04.Dashboards */
  "dashboards.pending-orders": "Pending Orders",
  "dashboards.completed-orders": "Completed Orders",
  "dashboards.refund-requests": "Refund Requests",
  "dashboards.new-comments": "New Comments",
  "dashboards.sales": "Sales",
  "dashboards.orders": "Orders",
  "dashboards.refunds": "Refunds",
  "dashboards.recent-orders": "Recent Orders",
  "dashboards.product-categories": "Product Categories",
  "dashboards.cakes": "Cakes",
  "dashboards.tickets": "Tickets",
  "dashboards.calendar": "Calendar",
  "dashboards.best-sellers": "Best Sellers",
  "dashboards.website-visits": "Website Visits",
  "dashboards.unique-visitors": "Unique Visitors",
  "dashboards.this-week": "This Week",
  "dashboards.last-week": "Lat Week",
  "dashboards.this-month": "This Month",
  "dashboards.conversion-rates": "Conversion Rates",
  "dashboards.per-session": "Per Session",
  "dashboards.profile-status": "Profile Status",
  "dashboards.payment-status": "Payment Status",
  "dashboards.work-progress": "Work Progress",
  "dashboards.tasks-completed": "Tasks Completed",
  "dashboards.payments-done": "Payments Done",
  "dashboards.order-stock": "Order - Stock",
  "dashboards.categories": "Categories",
  "dashboards.quick-post": "Quick Post",
  "dashboards.title": "Title",
  "dashboards.content": "Content",
  "dashboards.category": "Category",
  "dashboards.save-and-publish": "Save and Publish",
  "dashboards.top-viewed-posts": "Top Viewed Posts",
  "dashboards.posts": "Posts",
  "dashboards.pending-for-publish": "Pending for publish",
  "dashboards.users": "Users",
  "dashboards.on-approval-process": "On approval process",
  "dashboards.alerts": "Alerts",
  "dashboards.waiting-for-notice": "Waiting for notice",
  "dashboards.files": "Files",
  "dashboards.pending-for-print": "Pending for print",
  "dashboards.logs": "Logs",
  "dashboards.gogo": "GOGO",
  "dashboards.magic-is-in-the-details": "MAGIC IS IN THE DETAILS",
  "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
  "dashboards.advanced-search": "Advanced Search",
  "dashboards.toppings": "Toppings",
  "dashboards.type": "Type",
  "dashboards.keyword": "Keyword",
  "dashboards.search": "Search",
  "dashboards.top-rated-items": "Top Rated Items",

  /* 05.Pages */
  "pages.add-new": "ADD NEW",
  "pages.add-new-modal-title": "Add New Item",
  "pages.display-options": "Display Options",
  "pages.orderby": "Order By : ",
  "pages.product-name": "Product Name",
  "pages.category": "Category",
  "pages.description": "Description",
  "pages.status": "Status",
  "pages.cancel": "Cancel",
  "pages.submit": "Submit",
  "pages.delete": "Delete",
  "pages.another-action": "Another action",
  "pages.actions": "ACTIONS",
  "pages.header": "Header",
  "pages.details": "DETAILS",
  "pages.orders": "ORDERS",
  "pages.rating": "Rating",
  "pages.price": "Price",
  "pages.ingredients": "Ingredients",
  "pages.is-vegan": "Is Vegan",
  "pages.order-status": "Order Status",
  "pages.bake-progress": "Bake Progress",
  "pages.popularity": "Popularity",
  "pages.comments": "Comments",
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",
  "pages.mailing-info":
    "Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.",
  "pages.invoice-info":
    "Invoice template has an inline styled version for usage outside of the project as well as React version.",
  "pages.react-version": "React Version",
  "pages.inline-version": "Inline Styled Html Version",

  /* 06.Applications */

  /* 06.01.Chat */
  "chat.messages": "Messages",
  "chat.contacts": "Contacts",
  "chat.saysomething": "Say something..",

  /* 06.02.Survey */
  "survey.delete": "Delete",
  "survey.edit": "Edit",
  "survey.add-new": "ADD NEW",
  "survey.add-new-title": "Add New Survey",
  "survey.title": "Title",
  "survey.category": "Category",
  "survey.label": "Label",
  "survey.status": "Status",
  "survey.cancel": "Cancel",
  "survey.submit": "Submit",
  "survey.another-action": "Another action",
  "survey.display-options": "Display Options",
  "survey.orderby": "Order By : ",
  "survey.all-surveys": "All Surveys",
  "survey.completed-surveys": "Completed Surveys",
  "survey.categories": "Categories",
  "survey.active-surveys": "Active Surveys",
  "survey.labels": "Labels",

  /* 06.03.Todo */
  "todo.add-new": "ADD NEW",
  "todo.add-new-title": "Add New Todo",
  "todo.title": "Title",
  "todo.detail": "Detail",
  "todo.category": "Category",
  "todo.label": "Label",
  "todo.status": "Status",
  "todo.cancel": "Cancel",
  "todo.submit": "Submit",
  "todo.action": "Action",
  "todo.another-action": "Another action",
  "todo.display-options": "Display Options",
  "todo.orderby": "Order By : ",
  "todo.all-tasks": "All Tasks",
  "todo.pending-tasks": "Pending Tasks",
  "todo.completed-tasks": "Completed Tasks",
  "todo.categories": "Categories",
  "todo.labels": "Labels",

  /* 07.UI */

  /* 07.01.Alerts */
  "alert.rounded": "Rounded Alert",
  "alert.react-notifications": "React Notifications",
  "alert.outline": "Outline",
  "alert.primary": "Primary",
  "alert.secondary": "Secondary",
  "alert.info": "Info",
  "alert.success": "Success",
  "alert.warning": "Warning",
  "alert.error": "Error",
  "alert.filled": "Filled",
  "alert.primary-text": "This is a primary alert—check it out!",
  "alert.secondary-text": "This is a secondary alert—check it out!",
  "alert.success-text": "This is a success alert—check it out!",
  "alert.danger-text": "This is a danger alert—check it out!",
  "alert.warning-text": "This is a warning alert—check it out!",
  "alert.info-text": "This is a info alert—check it out!",
  "alert.light-text": "This is a light alert—check it out!",
  "alert.dark-text": "This is a dark alert—check it out!",
  "alert.default": "Default Alert",
  "alert.dismissing": "Dismissing Alert",
  "alert.dismissing-text":
    "Holy guacamole! You should check in on some of those fields below.",
  "alert.dismissing-without-animate-text":
    "I am an alert and I can be dismissed without animating!",

  /* 07.02.Badges */
  "badge.sizes": "Sizes",
  "badge.colors": "Colors",
  "badge.outline": "Outline",
  "badge.links": "Links",
  "badge.counter-badges": "Counter Badges",
  "badge.bootstrap-default": "Bootstrap Default",
  "badge.primary": "Primary",
  "badge.secondary": "Secondary",
  "badge.success": "Success",
  "badge.danger": "Danger",
  "badge.warning": "Warning",
  "badge.info": "Info",
  "badge.light": "Light",
  "badge.dark": "Dark",

  /* 07.03.Buttons */
  "button.default": "Bootstrap Default",
  "button.colors": "Colors",
  "button.rounded": "Rounded",
  "button.outline": "Outline",
  "button.states": "States",
  "button.sizes": "Sizes",
  "button.button-groups": "Button Groups",
  "button.large-button": "Large Button",
  "button.small-button": "Small Button",
  "button.extra-small-button": "Extra Small Button",
  "button.block-button": "Block Button",
  "button.active": "Active",
  "button.disabled": "Disabled",
  "button.basic": "Basic",
  "button.toolbar": "Toolbar",
  "button.nesting": "Nesting",
  "button.vertical-variation": "Vertical Variation",
  "button.checkbox-radio-button": "Checkbox and Radio Button",
  "button.checkbox": "Checkbox",
  "button.radio": "Radio",
  "button.radio-button": "Radio Button",
  "button.primary": "Primary",
  "button.secondary": "Secondary",
  "button.success": "Success",
  "button.danger": "Danger",
  "button.warning": "Warning",
  "button.info": "Info",
  "button.light": "Light",
  "button.dark": "Dark",
  "button.states-text":
    "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.click-here": "Click Here",
  "button.states-text-alternate":
    "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.primary-link": "Primary Link",
  "button.link": "Link",
  "button.primary-button": "Primary Button",
  "button.button": "Button",
  "button.left": "Left",
  "button.middle": "Middle",
  "button.right": "Right",
  "button.dropdown": "Dropdown",
  "button.dropdown-link": "Dropdown Link",

  /* 07.04.Cards */
  "cards.icon-card": "Icon Card",
  "cards.image-card": "Image Card",
  "cards.image-overlay-card": "Image Overlay Card",
  "cards.image-card-list": "Image Card List",
  "cards.tab-card": "Tab Card",
  "cards.user-card": "User Card",

  /* 07.05.Carousel */
  "carousel.basic": "Carousel Basic",
  "carousel.single": "Carousel Single",
  "carousel.without-controls": "Carousel Without Controls",

  /* 07.06.Charts */
  "charts.line": "Line Chart",
  "charts.polar": "Polar Chart",
  "charts.area": "Area Chart",
  "charts.scatter": "Scatter Chart",
  "charts.bar": "Bar Chart",
  "charts.radar": "Radar Chart",
  "charts.pie": "Pie Chart",
  "charts.doughnut": "Doughnut Chart",
  "charts.shadow": "Shadow",
  "charts.no-shadow": "No Shadow",

  /* 07.07.Collapse */
  "collapse.basic": "Basic",
  "collapse.toggle": "Toggle",
  "collapse.accordion": "Accordion",
  "collapse.controlled": "Controlled",
  "collapse.uncontrolled": "Uncontrolled",

  /* 07.08.Dropdowns */
  "dropdowns.basic": "Basic",
  "dropdowns.controlled": "Controlled",
  "dropdowns.uncontrolled": "Uncontrolled",
  "dropdowns.dropdown": "Dropdown",
  "dropdowns.header": "Header",
  "dropdowns.action": "Action",
  "dropdowns.another-action": "Another Action",
  "dropdowns.right": "Right",
  "dropdowns.left": "Left",
  "dropdowns.drop-directions": "Drop Directions",
  "dropdowns.dropright": "Dropright",
  "dropdowns.dropleft": "Dropleft",
  "dropdowns.small-button": "Small Button",
  "dropdowns.large-button": "Large Button",
  "dropdowns.sizing": "Sizing",
  "dropdowns.split-button": "Split Button Dropdowns",
  "dropdowns.dropup": "Dropup",

  /* 07.09.Editors */
  "editors.draftjs": "Draft.js",
  "editors.quill-standart": "Quill Standard",
  "editors.quill-bubble": "Quill Bubble",

  /* 07.10.Forms */
  "forms.basic": "Basic",
  "forms.email": "E-mail",
  "forms.email-muted": "We'll never share your email with anyone else.",
  "forms.password": "Password",
  "forms.submit": "Submit",
  "forms.horizontal": "Horizontal",
  "forms.radios": "Radios",
  "forms.first-radio": "First radio",
  "forms.second-radio": "Second radio",
  "forms.third-radio-disabled": "Third disabled radio",
  "forms.checkbox": "Checkbox",
  "forms.signin": "Sign in",
  "forms.top-labels-over-line": "Top Labels Over Line",
  "forms.tags": "Tags",
  "forms.date": "Date",
  "forms.state": "State",
  "forms.top-labels-in-input": "Top Labels In Input",
  "forms.email-u": "E-MAIL",
  "forms.password-u": "PASSWORD",
  "forms.tags-u": "TAGS",
  "forms.date-u": "DATE",
  "forms.state-u": "STATE",
  "forms.grid": "Form Grid",
  "forms.address": "Address",
  "forms.address2": "Address 2",
  "forms.city": "City",
  "forms.city-message": "Please select a city!",
  "forms.state-message": "Please select a state!",
  "forms.zip": "Zip",
  "forms.signup": "Signup",
  "forms.inline": "Inline",
  "forms.validation-availity": "Availity Reactstrap Validation",
  "forms.validation-formik": "Formik Validation",
  "forms.default": "Default",
  "forms.firstname": "First name",
  "forms.firstname-message": "Please enter your first name!",
  "forms.lastname": "Last name",
  "forms.lastname-message": "Please enter your last name!",

  /* 07.11.Form Components */
  "form-components.custom-inputs": "Custom Inputs",
  "form-components.checkboxes": "Checkboxes",
  "form-components.radios": "Radios",
  "form-components.inline": "Inline",
  "form-components.react-select": "React Select",
  "form-components.state-single": "State Single",
  "form-components.state-multiple": "State Multiple",
  "form-components.react-autosuggest": "React Autosuggest",
  "form-components.date-picker": "Date Picker",
  "form-components.date": "Date",
  "form-components.date-range": "Date Range",
  "form-components.date-with-time": "Date with Time",
  "form-components.embedded": "Embedded",
  "form-components.dropzone": "Dropzone",
  "form-components.drop-files-here": "Drop Files Here",
  "form-components.tags": "Tags",
  "form-components.switch": "Switch",
  "form-components.primary": "Primary",
  "form-components.secondary": "Secondary",
  "form-components.primary-inverse": "Primary Inverse",
  "form-components.secondary-inverse": "Secondary Inverse",
  "form-components.slider": "Slider",
  "form-components.double-slider": "Double Slider",
  "form-components.single-slider": "Single Slider",
  "form-components.rating": "Rating",
  "form-components.interactive": "Interactive",
  "form-components.readonly": "Readonly",
  "form-components.type-a-cake": "Type a Cake",
  "form-components.start": "Start",
  "form-components.end": "End",
  "form-components.tables": "Tables",
  /* 07.12.Icons */
  "icons.simplelineicons": "Simple Line Icons",
  "icons.iconsmind": "Iconsmind Icons",

  /* 07.13.Input Groups */
  "input-groups.basic": "Basic",
  "input-groups.sizing": "Sizing",
  "input-groups.small": "Small",
  "input-groups.default": "Default",
  "input-groups.large": "Large",
  "input-groups.checkboxes-and-radios": "Checkboxes and radios",
  "input-groups.multiple-inputs": "Multiple Inputs",
  "input-groups.first-and-last-name": "First and last name",
  "input-groups.multiple-addons": "Multiple Addons",
  "input-groups.button-addons": "Button Addons",
  "input-groups.button": "Button",
  "input-groups.buttons-with-dropdowns": "Buttons with Dropdowns",
  "input-groups.dropdown": "Dropdown",
  "input-groups.header": "Header",
  "input-groups.action": "Action",
  "input-groups.another-action": "Another Action",
  "input-groups.custom-select": "Custom Select",
  "input-groups.options": "Options",
  "input-groups.choose": "Choose...",
  "input-groups.custom-file-input": "Custom File Input",

  /* 07.14.Jumbotron */
  "jumbotron.hello-world": "Hello, world!",
  "jumbotron.lead":
    "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
  "jumbotron.lead-detail":
    "It uses utility classes for typography and spacing to space content out within the larger container.",
  "jumbotron.learn-more": "Learn more",

  /* 07.15.Modal */
  "modal.basic": "Basic",
  "modal.modal-title": "Modal title",
  "modal.launch-demo-modal": "Launch Demo Modal",
  "modal.scrolling-long-content": "Scrolling Long Content",
  "modal.backdrop": "Backdrop",
  "modal.backdrop-value": "Backdrop value",
  "modal.right-modal": "Right Modal",
  "modal.launch-right-modal": "Launch Right Modal",
  "modal.nested-modal": "Nested Modal",
  "modal.size": "Size",
  "modal.launch-large-modal": "Launch Large Modal",
  "modal.launch-small-modal": "Launch Small Modal",

  /* 07.16.Navigation */
  "nav.basic": "Nav Basic",
  "nav.active": "Active",
  "nav.disabled": "Disabled",
  "nav.disabled-link": "Disabled Link",
  "nav.link": "Link",
  "nav.longer-link": "Longer nav link",
  "nav.another-link": "Another Link",
  "nav.right": "Right",
  "nav.dropdown": "Dropdown",
  "nav.header": "Header",
  "nav.action": "Action",
  "nav.another-action": "Another Action",
  "nav.horizontal-alignment": "Nav Horizontal Alignment",
  "nav.vertical-alignment": "Nav Vertical Alignment",
  "nav.pills": "Nav Pills",
  "nav.fill-justify": "Nav Fill and Justify",
  "nav.pills-dropdowns": "Nav Pills with Dropdowns",
  "nav.pagination-basic": "Pagination Basic",
  "nav.pagination-sizing": "Pagination Sizing",
  "nav.large": "Large",
  "nav.small": "Small",
  "nav.center": "Center",
  "nav.pagination-alignment": "Pagination Alignment",
  "nav.breadcrumb-basic": "Breadcrumb Basic",

  /* 07.17.Popover & Tooltip */
  "popover-tooltip.popover": "Popover",
  "popover-tooltip.tooltip": "Tooltip",

  /* 07.18.Sortable */
  "sortable.columns": "Sorting Columns",
  "sortable.basic": "Basic",
  "sortable.handles": "Handles",

  /* 07.19.Maps */
  "maps.google": "Google",
  "maps.yandex": "Yandex",

  /* 07.20.Tables */
  "table.bootstrap-tables": "Bootstrap Tables",
  "table.bootstrap-basic": "Basic Table",
  "table.bootstrap-striped": "Striped Rows",
  "table.bootstrap-bordered": "Bordered Table",
  "table.bootstrap-borderless": "Borderless Table",
  "table.bootstrap-hoverable": "Hoverable Rows",
  "table.bootstrap-responsive": "Responsive Table",
  "table.react-tables": "React Tables",
  "table.react-pagination": "Pagination",
  "table.react-scrollable": "Scrollable",
  "table.react-advanced": "Filter, Length and Jump",
};

export default englishObject;
